import Callus from './Callus';
import {
  Link,
} from "react-router-dom";


function asd() {
  return (
<div>


    <div class="page-title">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="content">
                        <h2 class="title-banner">Commercial Mortgages</h2>
                    </div>
                </div>
                <div class="col-lg-3">
                    <ul class="breadcrumb">
                        <li><Link to="/" class="active">Home</Link></li>
                        <li><Link to="/mortgages">Mortgages</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="case-details">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="overview case-dt ">
                        <h3 class="title"><Link to="#">Build Your Future with Esstee Financial Services Because We Finance Your Success</Link></h3>
                        <p>
                            Esstee Financial Services is your trusted partner for commercial mortgages. We offer you tailored financial solutions to meet your unique needs. Are you looking to:
                        </p>
                    </div>
                    <div class="challange ">
                        <ul class="list-challange">
                            <li>
                                <span class="text">Purchase a new property</span>
                            </li>
                            <li>
                                <span class="text">Expand your business</span>
                            </li>
                            <li>
                                <span class="text">Refinance existing debt?  We’re always here for your assistance</span>
                            </li>
                        </ul>
                        <p>
                            Discover our range of services and find out why you should choose Esstee Finance. Are you ready to make the best decision of your life? Before that, take a look at what we have for you? 
                        </p>
                    </div>

                    <div class="featured featured-style1">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="flat-spacer sh38" data-desktop="38" data-sdesktop="0" data-mobi="0" data-smobi="0" ></div>
                                    <div class="content">
                                        <h4 class="heading">Our Services</h4>
                                    </div>
                                    <div class="iconbox-features type1">
                                        <div class="iconbox">
                                            <div class="iconbox-icon">
                                                <img src="images/practice/13.png" alt="images" />
                                            </div>
                                            <div class="iconbox-content">
                                                <h5 class="title"><Link to="#">Bridge Loans</Link></h5>
                                                <p class="description">
                                                    Do you need short-term financing? Our bridge loans present a fast path to cash, enabling you to take chances or tackle momentary financial concerns without unsettling your business activities.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="iconbox">
                                            <div class="iconbox-icon">
                                                <img src="images/practice/14.png" alt="images" />
                                            </div>
                                            <div class="iconbox-content">
                                                <h5 class="title"><Link to="#">Asset-Based Lending</Link></h5>
                                                <p class="description">
                                                    You can use your business assets to get a commercial mortgage now. Yes, our asset-based lending solutions offer you flexible financing options. We help you meet your financial needs efficiently based on the value of your assets.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="iconbox">
                                            <div class="iconbox-icon">
                                                <img src="images/practice/12.png" alt="images" />
                                            </div>
                                            <div class="iconbox-content">
                                                <h5 class="title"><Link to="#">Refinancing</Link></h5>
                                                <p class="description">
                                                    You can refinance your existing commercial mortgage with Esstee Finance. Do you know what you’ll get in return? You’ll get lower interest rates and improved cash flow. you might just free up some equity for company expansion. That sounds awesome! Our refinance solutions let you live your financial dreams.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="iconbox">
                                            <div class="iconbox-icon">
                                                <img src="images/practice/11.png" alt="images" />
                                            </div>
                                            <div class="iconbox-content">
                                                <h5 class="title"><Link to="#">Property Purchase Financing</Link></h5>
                                                <p class="description">
                                                    Acquiring commercial properties is a real hassle. But Esstee Financial Services has made it simple and seamless. We offer comprehensive financing solutions to help you acquire commercial properties like a breeze. Our team will work with you to secure the best mortgage terms. So, you can easily purchase space for your office, retail unit, or even industrial building. Our experts are just by your side.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="iconbox">
                                            <div class="iconbox-icon">
                                                <img src="images/practice/13.png" alt="images" />
                                            </div>
                                            <div class="iconbox-content">
                                                <h5 class="title"><Link to="#">Development Loans</Link></h5>
                                                <p class="description">
                                                    Are you thinking about creating a new business property? Our development loans offer the essential funds to make your vision a reality. We’re there for you at each step, from buying the land to constructing it.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Callus />
    <div class="case-details">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="mg-case-details d-md-flex featured-style1">
                        <div class="col-lg-6 col-md-12">
                            <div class="featured-post">
                                <div class="entry-image">
                                    <img src="images/case/10.jpg" alt="images" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="case-dt introduction-content">
                                <h3 class="title"><Link to="#">Why Commercial Mortgages Matter?</Link></h3>
                                <div class="challange case-dt mg-case-details">
                                    <ul class="list-challange">
                                        <li>
                                            <span class="text">Long-term Financing</span>
                                        </li>
                                        <li>
                                            <span class="text">Protection Against Inflation</span>
                                        </li>
                                        <li>
                                            <span class="text">Enhanced Corporate Image</span>
                                        </li>
                                        <li>
                                            <span class="text">Flexible Repayment Options</span>
                                        </li>
                                        <li>
                                            <span class="text">Support for Business Expansion</span>
                                        </li>
                                        <li>
                                            <span class="text">Customised Property Solutions</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="process case-dt mg-case-details featured-style1">
                        <h3 class="title"><Link to="#">Why Should You Choose Esstee Finance?</Link></h3>
                        <ul class="process-steps"> 
                            <li class="steps">
                                <div class="steps-name">
                                    <span class="number">1</span>
                                    <span class="name">Expert &Experienced Financial Advisory Service</span>
                                </div>
                            </li>
                            <li class="steps">
                                <div class="steps-name">
                                    <span class="number">2</span>
                                    <span class="name">Focused on Personalised Financial Solutions</span>
                                </div>
                            </li>
                            <li class="steps">
                                <div class="steps-name">
                                    <span class="number">3</span>
                                    <span class="name">Offering Competitive Rates</span>
                                </div>
                            </li>
                            <li class="steps">
                                <div class="steps-name">
                                    <span class="number">4</span>
                                    <span class="name">Quick and Efficient Process</span>
                                </div>
                            </li>
                            <li class="steps">
                                <div class="steps-name">
                                    <span class="number">5</span>
                                    <span class="name">Genteel Exceptional Customer Service</span>
                                </div>
                            </li>
                            <li class="steps">
                                <div class="steps-name">
                                    <span class="number">6</span>
                                    <span class="name">Comprehensive Financial Solutions</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="featured-banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-sm-12">
                    <div class="content">
                        <h6 class="title">Lets solve your problem today</h6>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12">
                    <div class="fl-btn">
                        <Link to="tel:+447718952509" class="hvr-vertical">Call Us</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>


    );
  }

  export default asd;
