import Callus from './Callus';
import {
  Link,
} from "react-router-dom";


function BridgingFinance() {
  return (
<div>




    <div class="page-title">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="content">
                        <h2 class="title-banner">Bridging Loans</h2>
                    </div>
                </div>
                <div class="col-lg-3">
                    <ul class="breadcrumb">
                        <li><Link to="/" class="active">Home</Link></li>
                        <li><Link to="/bridging-finance">Bridging Loans</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="case-details">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="overview case-dt ">
                        <h3 class="title"><Link to="#">Offering Quick Solutions for Your Immediate Financial Needs</Link></h3>
                        <p>
                            Esstee Finance welcomes you as your trusted partner for bridging loans. Our bridging loans offer a fast and flexible solution. Our services are also available to secure a property or finance a renovation. Does this information intrigue you to learn more? Stay with us to learn more about our services:
                        </p>
                    </div>

                    <div class="featured featured-style1">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="flat-spacer sh38" data-desktop="38" data-sdesktop="0" data-mobi="0" data-smobi="0"></div>
                                    <div class="content">
                                        <h4 class="heading">Our Services</h4>
                                    </div>
                                    <div class="iconbox-features type1">
                                        <div class="iconbox">
                                            <div class="iconbox-icon">
                                                <img src="images/practice/13.png" alt="images" />
                                            </div>
                                            <div class="iconbox-content">
                                                <h5 class="title"><Link to="#">Business Cash Flow Support</Link></h5>
                                                <p class="description">
                                                    Temporary cash flow challenges can disrupt business operations. Our bridging loans offer short-term financial support. It ensures your business continues to run smoothly without interruptions.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="iconbox">
                                            <div class="iconbox-icon">
                                                <img src="images/practice/12.png" alt="images" />
                                            </div>
                                            <div class="iconbox-content">
                                                <h5 class="title"><Link to="#">Quick Property Acquisition</Link></h5>
                                                <p class="description">
                                                    Our bridging loans provide immediate funding. It will help you secure properties without delay. Our efficient loan process prevents missed opportunities in real estate purchases.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="iconbox">
                                            <div class="iconbox-icon">
                                                <img src="images/practice/14.png" alt="images" />
                                            </div>
                                            <div class="iconbox-content">
                                                <h5 class="title"><Link to="#">Debt Consolidation</Link></h5>
                                                <p class="description">
                                                    Simplify your financial obligations by consolidating existing debts into a single and manageable loan. Our bridging loans reduce stress and help you pay off multiple debts.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="iconbox">
                                            <div class="iconbox-icon">
                                                <img src="images/practice/11.png" alt="images" />
                                            </div>
                                            <div class="iconbox-content">
                                                <h5 class="title"><Link to="#">Auction Purchases</Link></h5>
                                                <p class="description">
                                                    Our bridging loans are designed to provide the necessary financing promptly. These loans help meet auction deadlines.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="iconbox">
                                            <div class="iconbox-icon">
                                                <img src="images/practice/13.png" alt="images" />
                                            </div>
                                            <div class="iconbox-content">
                                                <h5 class="title"><Link to="#">Renovation Financing</Link></h5>
                                                <p class="description">
                                                    Are you planning to renovate a property before securing long-term financing? Our bridging loans are perfect for covering renovation costs. These loans help boost property value and switch to a conventional mortgage.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Callus />
    <div class="case-details">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class=" mg-case-details d-md-flex featured-style1">
                        <div class="case-dt introduction-content">
                          <h3 class="title"><Link to="#">Getting Bridging Loans from Esstee Financial Services Comes with the Following Perks</Link></h3>
                          <ul class="process-steps"> 
                              <li class="steps">
                                  <div class="steps-name">
                                      <span class="number">1</span>
                                      <span class="name">Quick Access to Funds</span>
                                  </div>
                              </li>
                              <li class="steps">
                                  <div class="steps-name">
                                      <span class="number">2</span>
                                      <span class="name">Short-Term Financial Solution</span>
                                  </div>
                              </li>
                              <li class="steps">
                                  <div class="steps-name">
                                      <span class="number">3</span>
                                      <span class="name">Competitive Interest Rates</span>
                                  </div>
                              </li>
                              <li class="steps">
                                  <div class="steps-name">
                                      <span class="number">4</span>
                                      <span class="name">Simplified Approval Process</span>
                                  </div>
                              </li>
                              <li class="steps">
                                  <div class="steps-name">
                                      <span class="number">5</span>
                                      <span class="name">Supports Property Transactions</span>
                                  </div>
                              </li>
                              <li class="steps">
                                  <div class="steps-name">
                                      <span class="number">6</span>
                                      <span class="name">Auction Purchases</span>
                                  </div>
                              </li>
                              <li class="steps">
                                  <div class="steps-name">
                                      <span class="number">7</span>
                                      <span class="name">Maintains Business Operations</span>
                                  </div>
                              </li>
                              <li class="steps">
                                  <div class="steps-name">
                                      <span class="number">8</span>
                                      <span class="name">Customisation & Flexibility </span>
                                  </div>
                              </li>
                          </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class=" mg-case-details d-md-flex featured-style1">
                        <div class="featured-post">
                            <div class="entry-image">
                                <img src="images/case/11.jpg" alt="images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="featured-banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-sm-12">
                    <div class="content">
                        <h6 class="title">Lets solve your problem today</h6>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12">
                    <div class="fl-btn">
                        <Link to="tel:+447718952509" class="hvr-vertical">Call Us</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>


    );
  }

  export default BridgingFinance;
