import Callus from './Callus';
import {
  Link,
} from "react-router-dom";


function Home() {
  return (
<div>
    <div>
      <div id="myCarousel" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">
          <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
          <li data-target="#myCarousel" data-slide-to="1"></li>
        </ol>
        <div className="carousel-inner">
          <div className="item active">
            <img className="dis" src="images/transparent-head/04.webp" alt="Los Angeles"  />
            <img className="mis" src="images/transparent-head/041.webp" alt="Los Angeles"  />
            <div className="carousel-caption tzc">
              <h3>EXPERT GUIDANCE</h3>
              <h2 className="mb-4">Unlock Your Dream Home</h2>
              <p className="mb-5">Finding the perfect home is exciting. <br />Our expert mortgage solutions make it easy and affordable for you.</p>
              <Link to="/mortgages" className="astylc">read more</Link>
            </div>
          </div>
          <div className="item">
            <img className="dis" src="images/transparent-head/05.webp" alt="Chicago"  />
            <img className="mis" src="images/transparent-head/051.webp" alt="Chicago"  />
            <div className="carousel-caption tzc">
              <h3>SIMPLIFY PROCESS</h3>
              <h2 className="mb-4">Path to Home Ownership</h2>
              <p className="mb-5">Navigating the property market is challenging. <br />We simplify the mortgage process, making it stress-free and straightforward.</p>
              <Link to="/secured-commerical-loans" className="astylc">read more</Link>
            </div>
          </div>
        </div>
      </div>
    </div>




        <section className="iconbox-home clearfix">
            <div className="container">
                <div className="iconbox-features type2">
                    <div className="iconbox iconbox-1">
                        <div className="bck-stand"></div>
                        <div className="bnc">
                            <div className="iconbox-wrap">
                                <div className="iconbox-icon">
                                    <img src="images/home1/01.png" alt="images" width="64" />
                                </div>
                                <div className="iconbox-content">
                                    <h3 className="title text-uppercase hfc"><Link to="mortgages.php">Commerical Mortgages </Link></h3>
                                    <p className="description">
                                        Financing solutions for buying or refinancing commercial properties.
                                    </p>
                                    <div className="fl-btn hfc">
                                        <Link to="mortgages">read more</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="iconbox iconbox-2 active">
                        <div className="bck-stand"></div>
                        <div className="bnc">
                            <div className="iconbox-wrap">
                                <div className="iconbox-icon">
                                    <img src="images/home1/02.png" alt="images" width="64" />
                                </div>
                                <div className="iconbox-content">
                                    <h3 className="title text-uppercase hfc"><Link to="secured-commerical-loans.php">Secured Commerical Loans</Link></h3>
                                    <p className="description">
                                        Short-term funding to bridge gaps between property transactions.
                                    </p>
                                    <div className="fl-btn hfc">
                                        <Link to="secured-commerical-loans">read more</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="iconbox iconbox-3">
                        <div className="bck-stand"></div>
                        <div className="bnc">
                            <div className="iconbox-wrap">
                                <div className="iconbox-icon">
                                    <img src="images/home1/03.png" alt="images" width="64" />
                                </div>
                                <div className="iconbox-content">
                                    <h3 className="title text-uppercase hfc"><Link to="bridging-finance.php">Bridging Finance</Link></h3>
                                    <p className="description">
                                        Loans secured against commercial property for flexible financing options.
                                    </p>
                                    <div className="fl-btn hfc">
                                        <Link to="bridging-finance">read more</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="about-home">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="featured-post">
                            <div className="entry-image">
                                <img src="images/home1/04.webp" alt="images" width="470" height="601" />
                            </div>
                            <div className="image-move">
                                <img src="images/home1/05.png" alt="images" className="oval-one"  width="25" />
                                <img src="images/home1/06.png" alt="images" className="oval-two"  width="40" />
                                <img src="images/home1/07.png" alt="images" className="oval-three"  width="30" />
                                <img src="images/home1/08.png" alt="images" className="oval-four"  width="30" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="flat-spacer" data-desktop="36" data-sdesktop="20" data-mobi="0" data-smobi="0"></div>
                        <div className="content">
                            <div className="title hstyl"><Link to="#">Welcome to <br/>ESSTEE Finance</Link></div>
                            <h4 className="fsh4 text-uppercase mb-2 hfc">Your Trusted Partner for Mortgages</h4>
                            <p className="description mb-2">
                                Welcome to ESSTEE FINANCE, your trusted partner for mortgage solutions. We specialize in simplifying the mortgage process, ensuring it's straightforward whether you're a first-time buyer, remortgaging, or expanding your property portfolio.
                            </p>
                            <p className="description mb-4">
                                At ESSTEE FINANCE, we leverage our extensive experience in the market to offer personalized mortgage solutions. We're here to help you navigate the complexities of securing a mortgage with clarity and confidence.
                            </p>
                            <div className="fl-btn">
                                <Link to="about" className="hvr-vertical  hfc">read more</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        

        <section className="facts shstyl">
            <div className="container">
                <div className="flat-counter">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h1 className="rwhstyl">Key Advantages</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="practice-featured">
            <div className="practice-area">
                <div className="container">
                    <div className="title-section text-center">
                        

                    </div>
                    <div className="practice-area-wrap">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12" width="64">
                                <div className="image-box-law">
                                    <div className="iconbox-icon ccicon">
                                        <img src="images/practice/03.png" alt="images" width="64" />
                                    </div>
                                    <div className="content-law">
                                        <h4 className="name text-uppercase hfc">Expert Knowledge</h4>
                                        <p className="description">Extensive expertise in London’s mortgage and financial markets.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12" width="64">
                                <div className="image-box-law">
                                    <div className="iconbox-icon ccicon">
                                        <img src="images/practice/04.png" alt="images" width="64" />
                                    </div>
                                    <div className="content-law">
                                        <h4 className="name text-uppercase hfc">Client Focused</h4>
                                        <p className="description">Prioritizing client needs to deliver personalized financial solutions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="image-box-law">
                                    <div className="iconbox-icon ccicon">
                                        <img src="images/practice/05.png" alt="images" width="64" />
                                    </div>
                                    <div className="content-law">
                                        <h4 className="name text-uppercase hfc">Reliable Partnership</h4>
                                        <p className="description">Building trust through consistent, dependable service and support.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="image-box-law">
                                    <div className="iconbox-icon ccicon">
                                        <img src="images/practice/06.png" alt="images" width="64" />
                                    </div>
                                    <div className="content-law">
                                        <h4 className="name text-uppercase hfc">Innovative Solutions</h4>
                                        <p className="description">Creative financial strategies tailored to meet diverse client requirements.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="image-box-law">
                                    <div className="iconbox-icon ccicon">
                                        <img src="images/practice/07.png" alt="images" width="64" />
                                    </div>
                                    <div className="content-law">
                                        <h4 className="name text-uppercase hfc">Proven Success</h4>
                                        <p className="description">Demonstrated track record of successful mortgage and financial outcomes.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="image-box-law">
                                    <div className="iconbox-icon ccicon">
                                        <img src="images/practice/08.png" alt="images" width="64" />
                                    </div>
                                    <div className="content-law">
                                        <h4 className="name text-uppercase hfc">Comprehensive Care</h4>
                                        <p className="description">Holistic approach to address all aspects of your financial needs.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="featured featured-style2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="featured-post">
                                <div className="entry-image">
                                    <img src="images/home1/14.webp" alt="images" width="570" height="683" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="flat-spacer" data-desktop="63" data-sdesktop="63" data-mobi="30" data-smobi="30"></div>
                            <div className="content">
                                

                                <h4 className="heading text-uppercase hfc">Get Started Today</h4>
                                <p className="description text-dark">
                                    Ready to take the next step? Contact us to schedule a free consultation with our experienced mortgage advisors. Whether you prefer to meet in person at our office or virtually, we're here to help. Our goal is to provide personalized guidance and support, ensuring you make informed decisions and achieve your property goals with confidence.
                                </p>
                                <p className="description text-dark">
                                    Contact us today to begin exploring your options and discover how ESSTEE FINANCE can assist you in achieving your property and financial objectives.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <section className="featured featured-style3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                        <div className="content">
                            <div className="caption hfc">ESSTEE Finanace</div>
                            <h4 className="heading text-uppercase hfc">Why Choose Us?</h4>
                            <p className="description">
                                ESSTEE FINANCE stands out for expert, personalized mortgage solutions. We prioritize transparency, clear advice, and client satisfaction for confident property decisions.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12">
                        <div className="iconbox-features type3">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-12">
                                    <div className="iconbox">
                                        <div className="iconbox-icon">
                                            <img src="images/practice/11.png" alt="images"  width="64" />
                                        </div>
                                        <div className="iconbox-content">
                                            <h5 className="title hfc"><Link to="#">Our<br />Expertise</Link></h5>
                                            <p className="description">
                                                Benefit from our extensive experience in mortgage market for optimal solutions.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <div className="iconbox">
                                        <div className="iconbox-icon">
                                            <img src="images/practice/12.png" alt="images"  width="64" />
                                        </div>
                                        <div className="iconbox-content">
                                            <h5 className="title hfc"><Link to="#">Personalized <br />Service</Link></h5>
                                            <p className="description">
                                                Receive tailored guidance from dedicated advisors focused on your financial goals.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <div className="iconbox">
                                        <div className="iconbox-icon">
                                            <img src="images/practice/13.png" alt="images"  width="64" />
                                        </div>
                                        <div className="iconbox-content">
                                            <h5 className="title hfc"><Link to="#">Transparent and <br /> Honest Advice </Link></h5>
                                            <p className="description">
                                                We provide clear advice to ensure informed decision-making without surprises.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <div className="iconbox">
                                        <div className="iconbox-icon">
                                            <img src="images/practice/14.png" alt="images"  width="64" />
                                        </div>
                                        <div className="iconbox-content">
                                            <h5 className="title hfc"><Link to="#">Commitment to <br /> Satisfaction</Link></h5>
                                            <p className="description">
                                                Our success is driven by client satisfaction, building trust and lasting relationships.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="style-text"></div>
        </section>

      <Callus />

</div>


    );
  }

  export default Home;
