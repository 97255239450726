import Callus from './Callus';
import {
  Link,
} from "react-router-dom";


function Conveyancing() {
  return (
<div>
    <div class="page-title">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="content">
                        <h2 class="title-banner">Conveyancing</h2>
                        <p>Comming Soon</p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <ul class="breadcrumb">
                        <li><Link to="/" class="active">Home</Link></li>
                        <li><Link to="/conveyancing">Conveyancing</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <Callus />
</div>


    );
  }

  export default Conveyancing;
