
function Callus() {
  return (
<div>
        <div className="case-evaluation">
            <div className="overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                        <div className="mt-4">
                            <div className="themesflat-content-box sp0" data-padding="0% 0% 0% 0%" data-sdesktoppadding="0% 0% 0% 0%" data-ssdesktoppadding="0% 0% 0% 0%" data-mobipadding="100px 0% 0% 0%" data-smobipadding="100px 0% 0% 0%">
                                <form action="#" className="form-evaluation">
                                    <div className="wrap-evaluation">
                                        <h5 className="title text-uppercase hfc">Free Consultation</h5>
                                        <div className="your-name mg-text">
                                            <input type="text" className="your-name" placeholder="Your name" />
                                        </div>
                                        <div className="your-email mg-text">
                                            <input type="text" className="your-email" placeholder="Your email" />
                                        </div>
                                        <div className="your-phone mg-text">
                                            <input type="text" className="your-phone" placeholder="Your phone no" />
                                        </div>
                                        <div className="subject mg-text">
                                            <input type="text" className="subject" placeholder="Subject" />
                                        </div>
                                        <div className="message-wrap mg-text">
                                            <textarea name="message" id="message" rows="8" placeholder="Your message"></textarea>
                                        </div>
                                        <div className="fl-btn">
                                            <button className="hvr-vertical hfc">Request call back</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12">
                        <div className="content-evaluation">
                            <div className="themesflat-content-box sp10" data-padding="113px 0% 101px 100px" data-sdesktoppadding="113px 0% 101px 0" data-ssdesktoppadding="113px 0% 101px 0" data-mobipadding="50px 0 100px 0" data-smobipadding="50px 0 100px 0">
                                <div className="caption hfc">Quick Response Guaranteed</div>
                                <h4 className="heading text-uppercase hfc">Expect a rapid response when you reach out to us</h4>
                                <p className="description">
                                    Count on us for a prompt reply to your inquiry. Our team is dedicated to ensuring you receive timely and helpful assistance, whether you're seeking mortgage advice, insurance information, or property services.
                                </p>
                                <div className="flat-spacer" data-desktop="90" data-sdesktop="60" data-mobi="25" data-smobi="25"></div>
                                <div className="call-us">
                                    <div className="text">
                                        Or Call us 
                                    </div>
                                    <h3 className="phone-number">
                                        0771 895 2509 
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>


    );
  }

export default Callus;

