import {
  Link,
} from "react-router-dom";


function asd() {
  return (
<div>

    <div class="page-title">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="content">
                        <h2 class="title-banner">Contact</h2>
                    </div>
                </div>
                <div class="col-lg-6">
                    <ul class="breadcrumb">
                        <li><Link to="/" class="active">Home</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="contact clearfix">
        <div class="container">
            <div class="contact-us">
                <div class="title-section text-center">
                    <h3 class="flat-title">Connect With Us</h3>
                </div>
                <div class="contact-options">
                    <div class="icon-box">
                        <div class="icon">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                        </div>
                        <div class="content-info">
                            <h4 class="name">Address</h4>
                            <div class="info-wrap">
                                <p>47 High Street Slough, SL1 1EL, UK</p>
                            </div>
                        </div>
                    </div>
                    <div class="icon-box border-both-sides">
                        <div class="icon">
                            <i class="fa fa-phone" aria-hidden="true"></i>
                        </div>
                        <div class="content-info">
                            <h4 class="name">Phone Number</h4>
                            <div class="info-wrap">
                                <p>(+44)0771 895 2509</p>
                            </div>
                        </div>
                    </div>
                    <div class="icon-box">
                        <div class="icon">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                        </div>
                        <div class="content-info">
                            <h4 class="name">Email Address</h4>
                            <div class="info-wrap">
                                <p>info@essteefinance.co.uk</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="write-something">
        <div class="container">
            <div class="title-section text-center">
                <h3 class="flat-title">Write Us Something</h3>
            </div>
            <form action="#" class="form-message-pct">
                <div class="text-wrap d-md-flex clearfix">
                    <div class="wr-sm">
                        <input type="text" class="your-name" placeholder="Your name" />
                    </div>
                    <div class="wr-sm">
                        <input type="text" class="your-email" placeholder="Your email" />
                    </div>
                    <div class="wr-sm">
                        <input type="text" class="your-phone" placeholder="Your phone" />
                    </div>
                </div>
                <textarea name="comment" id="comment-message" rows="8" placeholder="Write your message here"></textarea>
                <div class="fl-btn">
                    <button class="hvr-vertical">send now</button>
                </div>
            </form>
        </div>
    </div>

</div>


    );
  }

  export default asd;
