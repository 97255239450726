import {
  Link,
} from "react-router-dom";

const togglemenu = () => {
    if (window.innerWidth < 992) {
        document.getElementById("togglemenu").classList.toggle("active");
        if (document.getElementById("mainnav")) {
            document.getElementById("mainnav").id = "mainnav-mobi";
            document.getElementById("mainnav-mobi").style.possition = "absolute";
            document.getElementById("mainnav-mobi").style.left = "0";
            document.getElementById("mainnav-mobi").style.top = "120px";
            document.getElementById("mainnav-mobi").style.display = "block";
            var cDiv = document.getElementById("mainnav-mobi").childNodes[0].childNodes[0].childNodes[0].style.display = "block";
            // var cDiv = document.getElementById("mainnav-mobi").childNodes[0].childNodes[0].childNodes[0].setAttribute("onclick", "{togglemenu}");
            var cDiv = document.getElementById("mainnav-mobi").childNodes[0].childNodes[1].childNodes[0].style.display = "block";
            var cDiv = document.getElementById("mainnav-mobi").childNodes[0].childNodes[2].childNodes[0].style.display = "block";
            var cDiv = document.getElementById("mainnav-mobi").childNodes[0].childNodes[3].childNodes[0].style.display = "block";
            var cDiv = document.getElementById("mainnav-mobi").childNodes[0].childNodes[4].childNodes[0].style.display = "block";
        }else if (document.getElementById("mainnav-mobi")) {
            if (document.getElementById("mainnav-mobi").style.display == "none") {
                document.getElementById("mainnav-mobi").style.display = "block";
            }else{
                document.getElementById("mainnav-mobi").style.display = "none";
            }
        }
    }

}


function Nav() {
  return (




<div className="counter-scroll">
    <div className="top-bar">
        <div className="container">
            <div className="row">
                <div className="col-lg-7 col-md-7 d-none d-sm-block">
                    <ul className="flat-information d-lg-flex align-items-center">
                        <li className="email"><Link to="mailto:info@essteefinance.co.uk" title="Email">info@essteefinance.co.uk</Link></li>
                        <li className="address"><Link to="https://www.google.com/maps/place/47+High+St,+Slough+SL1+1EL,+UK/@51.510239,-0.5970941,17z/data=!3m1!4b1!4m6!3m5!1s0x48767acc661ae6d3:0x5c13974bf93f0a56!8m2!3d51.510239!4d-0.5970941!16s%2Fg%2F11l39c4rdp?entry=ttu" title="Address" target="_blank">47 High Street Slough, SL1 1EL, UK</Link></li>
                    </ul>
                </div>
                <div className="col-lg-5 col-md-5">
                    <div className="flat-contact-us d-lg-flex align-items-center">
                        <Link to="tel:+447718952509" className="phone">0771 895 2509</Link>
                        <Link to="/contact" className="cosulting hvr-vertical d-none d-sm-inline">CONTACT US
                            <div className="border-animate">
                                <div className="top"></div>
                                <div className="right"></div>
                                <div className="bottomb"></div>
                                <div className="left"></div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <header id="header" className="header bg-color">
        <div className="container">
            <div className="flex-header">
                <div id="logo" className="logo">
                    <Link to="/" title="Logo">
                      <img src="images/logo/logo.webp" data-width="211" data-height="34" alt="images" data-retina="images/logo/logo.webp" />
                    </Link>
                </div>
                <div className="content-menu">
                    <div className="nav-wrap">
                        <div className="btn-menu" id="togglemenu" onClick={togglemenu}><span></span></div>
                        <nav id="mainnav" className="mainnav">
                            <ul className="menu">
                                <li>
                                    <Link to="/" className="active" onClick={togglemenu}>Home</Link>
                                </li>
                                <li>
                                    <Link to="/mortgages" onClick={togglemenu}>MORTGAGES</Link>
                                </li>
                                <li>
                                    <Link to="/secured-commerical-loans" onClick={togglemenu}>SECURED LOANS</Link>
                                </li>
                                <li>
                                    <Link to="/bridging-finance" onClick={togglemenu}>BRIDGING FINANCE</Link>
                                </li>
                                <li>
                                    <Link >
                                      MORE <img className="mdaimgstyl" src="images/icon/da.png" alt="downarrow" />
                                    </Link>
                                    <ul className="sub-menu">
                                        <li><Link to="/protection" onClick={togglemenu}>PROTECTION</Link></li>
                                        <li><Link to="/insurance" onClick={togglemenu}>INSURANCE</Link></li>
                                        <li><Link to="/conveyancing" onClick={togglemenu}>CONVEYANCING</Link></li>
                                        <li><Link to="/about" onClick={togglemenu}>About Us</Link></li>
                                    </ul>
                                </li>
                            </ul>                                   
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </header>



    <div id="fixed-social">
      <div>
        <Link to="https://www.facebook.com/" aria-label="Facebook" className="fixed-facebook" target="_blank"><i className="fa fa-facebook"></i> <span>Facebook</span></Link>
        </div>
        <div>
            <Link to="https://www.instagram.com/" aria-label="Instagram" className="fixed-instagrem" target="_blank"><i className="fa fa-instagram"></i> <span>Instagram</span></Link>
        </div>
        <div>
            <Link to="https://www.linkedin.com/" aria-label="Linkedin" className="fixed-linkedin" target="_blank"><i className="fa fa-linkedin"></i> <span>LinkedIn</span></Link>
        </div>
        <div>
            <Link to="https://x.com/"  aria-label="Twitter" className="fixed-twitter" target="_blank"><i className="fa fa-twitter"></i> <span>Twitter</span></Link>
        </div>
    </div>
</div>



    );
  }

  export default Nav;
