import {
  Link,
} from "react-router-dom";


function Footer() {
  return (

    <footer id="footer" className="footer">
        <div className="footer-widgets">
            <div className="container">
                <div className="footer-top">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="widget widget-text mg-footer-mbb">
                                <div className="content">
                                    <div className="images-logo">
                                        <img src="images/logo/logo-white.webp" alt="images" />
                                    </div>
                                    <p className="mb-0">
                                        ESSTEE FINANCE offers expert mortgage and financial solutions tailored to your needs. Trust us for personalized, transparent, and efficient service.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="widget widget-services mg-footer-mbb">
                                <h2 className="widget-title text-uppercase hfc">Services</h2>
                                <div className="content">
                                    <ul className="widget-menu">
                                        <li><Link to="mortgages">Commercial Mortgage</Link></li>
                                        <li><Link to="secured-commerical-loans">Secured Commercial Loan</Link></li>
                                        <li><Link to="bridging-finance">Bridge Finance</Link></li>
                                        <li><Link to="protection">Protection</Link></li>
                                        <li><Link to="insurance">Insurance</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="widget widget-contact mg-footer-mbb">
                                <h2 className="widget-title text-uppercase hfc">Contact</h2>
                                <div className="content">
                                    <ul>
                                        <li><span className="text address">47 High Street Slough, SL1 1EL<br />London, UK</span></li>
                                        <li><span className="text phone">0771 895 2509</span></li>
                                        <li><span className="text email">info@essteefinance.co.uk</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="container">
                    <div className="bottom-wrap">
                        <div className="row">
                            <div className="col-lg-5 col-md-12">
                                <div className="copyright">
                                    All rights reserved  ©  <Link to="/" className=" hfc">ESSTEE Finance</Link>  2019
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="menu-footer">
                                    <ul>
                                        <li><Link to="#">Terms & Condition</Link></li>
                                        <li><Link to="#">Privacy Policy</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Link id="scroll-top" className="show"></Link>
    </footer>


    );
  }

  export default Footer;
