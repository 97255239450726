import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Conveyancing from './Conveyancing';
import BridgingFinance from './BridgingFinance';
import Insurance from './Insurance';
import Mortgages from './Mortgages';
import Protection from './Protection';
import SecuredCommericalLoans from './SecuredCommericalLoans';
import Nav from './Nav';
import Footer from './Footer';
import reportWebVitals from './reportWebVitals';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Nav />
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/conveyancing' element={<Conveyancing/>} />
        <Route path='/bridging-finance' element={<BridgingFinance/>} />
        <Route path='/mortgages' element={<Mortgages/>} />
        <Route path='/insurance' element={<Insurance/>} />
        <Route path='/protection' element={<Protection/>} />
        <Route path='/secured-commerical-loans' element={<SecuredCommericalLoans/>} />
      </Routes>
      <Footer />
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
